import './App.css';
import AppMain from './pages/AppMain'
import React from "react";
import { AppBloc, AppContext, AppContextProvider } from './contexts/AppContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { AppSession } from './services/session/AppSession';
import { config } from './configs/Config';
import Cookies from 'js-cookie';
import {LicenseService} from "./services/LicenseService";
import {useSearchParams} from "react-router-dom";
import LicensePage from "./pages/LicensePage";
const querystring = require('querystring');


function App() {
  const [appBloc, setAppBloc] = useState();
  const [checkLicense, setCheckLicense] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const licenseService = new LicenseService();
  const [targetMachineId, setTargetMachineId] = useState(null);
  useEffect(() => {
    let session = new AppSession();
    console.log(`host: ${config.HOST_WS}`);
    let websocket = new WebSocket(config.HOST_WS);
    let _appBloc = new AppBloc({ session, websocket })
    setAppBloc(_appBloc);
    let machineId = null;
    let platform = 'web';

    if (searchParams.get('data')) {
      console.log(searchParams.get('data'))
      let data = JSON.parse(searchParams.get('data'))
      if (data !== undefined) {
        console.log("platform = %s, machineId = %s, stationId = %s", data.platform, data.machineId, data.stationId);
        machineId = data.machineId;
        platform = data.platform;
        setTargetMachineId(machineId);
      }
    }

    // let query = querystring.parse(global.location.search);
    // if (query !== null && query.hasOwnProperty('data') && query['data'] !== undefined) {
    //   let data = JSON.parse(query['data'])
    //   if (data !== undefined) {
    //     console.log("platform = %s, machineId = %s, stationId = %s", data.platform, data.machineId, data.stationId);
    //     machineId = data.machineId;
    //     platform = data.platform;
    //     setTargetMachineId(machineId);
    //   }
    //   // let data = JSON.parse(query['?stationId'])
    //   // console.log("window data " + query['?stationId']);
    // }

    console.log(`platform: ${platform}, machineId: ${machineId}`)

    if (platform === 'app') {
      Cookies.set('platform', 'app');
      let license = localStorage.getItem('license');
      console.log(`license: ${license}`)
      if (license) {
        licenseService.checkLicense(machineId, license).then(
          data => {
            console.log(`check license response: ${data}`)
            if (data.code === 0) {
              setCheckLicense(false);
            }
          }
        );
      }
      setCheckLicense(true);
    } else {
      setCheckLicense(false);
    }
  }, [searchParams]);

  const showDialog = (message) => {
    alert(message);
  }
  
  return (
    (checkLicense ?
    <div>
      <LicensePage onSubmit={(key) => {
        licenseService.checkLicense(targetMachineId, key).then(
          data => {
            console.log(`check license response: ${data}`)
            if (data.code === 0) {
              localStorage.setItem('license', key);
              setCheckLicense(false);
            } else {
              showDialog(data.message)
            }
          }
        );
      }} />
    </div> :
        <AppContextProvider appProps={appBloc}>
          <AppContext.Consumer>
            {({ appProps }) => {
              if (appProps !== undefined && appProps !== null) {
                return(
                  <AppMain
                    appBloc={appProps}
                  />
                );
              } else {
                return <></>;
              }
            }}
          </AppContext.Consumer>
        </AppContextProvider>
    )
  );
}

export default App;
