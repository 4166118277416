import ServiceBase from './service-base';

export class StationService extends ServiceBase {
    baseUri = '';

    async getStationById(id) {
        return (await this.get('/api/station/v1/' + id)).data;
    }

    async deleteStation(id) {
        return (await this.delete('/api/station/v1/' + id)).data;
    }

    async addStation({ hardwareId, stationName }) {
        return (await this.post('/api/station/v1/add', { hardwareId, stationName })).data;
    }

    async addMapItem({ stationId, type, phase, x, y, rotate, scale, content }) {
        return (await this.post('/api/station/v1/addMapItem', { stationId, type, phase, x, y, rotate, scale, content })).data;
    }

    async closeStream(id) {
        return (await this.post('/api/station/v1/closeStream/' + id, {})).data;
    }

    async getCurrentCycleForm(id) {
        return (await this.get('/api/station/v1/getCurrentCycleForm/' + id)).data;
    }

    async setCycleTime(obj) {
        return (await this.post('/api/station/v1/setCycleTime', obj)).data;
    }

    async setBackgroundImage({ stationId, imageUrl }) {
        return (await this.post('/api/station/v1/setBackgroundImage', { stationId, imageUrl })).data;
    }

    async getStations() {
        return (await this.get('/api/station/v1/getList')).data;
    }

    async getMapSetting(id) {
        return (await this.get('/api/station/v1/getMapSetting/' + id)).data;
    }

    async getStationSetting(id) {
        return (await this.get('/api/station/v1/getStationSetting/' + id)).data;
    }

    async addLaneControl({ stationId, lane }) {
        return (await this.post('/api/station/v1/laneControl', { stationId, lane })).data;
    }

    async openStream(id) {
        return (await this.post('/api/station/v1/openStream/' + id, {})).data;
    }

    async powerControl({ stationId, power }) {
        return (await this.post('/api/station/v1/powerControl', { stationId, power })).data;
    }

    async deleteMapItem(id) {
        return (await this.delete('/api/station/v1/removeMapItem/' + id)).data;
    }

    async getActiveTime(id) {
        return (await this.get('/api/station/v1/getActiveTime/' + id)).data;
    }

    async setActiveTime({ stationId, start, end, valid = true }) {
        return (await this.post('/api/station/v1/setActiveTime', { stationId, start, end, valid })).data;
    }

    async syncTime(stationId) {
        return (await this.post('/api/station/v1/syncTime', { stationId })).data;
    }

    async updateMapItem({ itemId, type, x, y, rotate, scale, content, phase }) {
        return (await this.post('/api/station/v1/updateMapItem', { itemId, type, x, y, rotate, scale, content, phase })).data;
    }

    async updatePhase({ stationId, phase, phaseName }) {
        return (await this.put('/api/station/v1/updatePhase', { stationId, phase, phaseName })).data;
    }
}
