import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { SignalType } from '../../pages/ControlPanelPage';
import { ItemArrowDirection, ItemText } from '../../pages/ControlPanelPage/components';
import { StationService } from '../../services/StationService';
import { signColors } from '../../contants';

export default function ListMapIcon({ id, onTab, listItems, setListItems, setBackgroundUrl, dataDevideSocket }) {
  const stationService = new StationService();

  useEffect(async () => {
    if (id) {
      const data = await stationService.getMapSetting(id);
      if (data) {
        setBackgroundUrl(data.data.backgroundUrl);
        setListItems(data.data.mapItems);
      }
    }
  }, [id]);

  const updateChange = (id, x, y, rotate, scale) => {
    const index = listItems.findIndex(x => x.id === id);
    if (index !== -1) {
      listItems[index].position = {
        x: x,
        y: y,
        rotate: rotate,
        scale: scale,
      }
      setListItems(listItems);
    }
  }

  const getColorArrow = (phaseId) => {
    const idx = dataDevideSocket?.phasestatesList?.findIndex(item => item.index === phaseId);
    if (idx >= 0) {
      return signColors[dataDevideSocket.phasestatesList[idx].signstate];
    }

    // Red
    return '#F5222D'
  }

  const getCounter = (phaseId) => {
    return dataDevideSocket?.phasestatesList?.find(x => x.index === phaseId)?.counter;
  }

  return (
    <>
      {listItems.map(item => 
        item.type === SignalType.text || item.type === SignalType.countdown ?
          <ItemText
            id={item.id}
            key={`target-text-${item.id}`}
            textValue={item.content || getCounter(item.phase)}
            x={item.position.x}
            y={item.position.y}
            rotate={item.position.rotate}
            scale={item.position.scale}
            onTab={onTab}
            onChange={(x, y, rotate, scale) => { updateChange(item.id, x, y, rotate, scale) }}
          /> :
          <ItemArrowDirection
            id={item.id}
            key={`target-${item.id}`}
            direction={item.type}
            color={getColorArrow(item.phase)}
            x={item.position.x}
            y={item.position.y}
            rotate={item.position.rotate}
            scale={item.position.scale}
            onTab={onTab}
            onChange={(x, y, rotate, scale) => { updateChange(item.id, x, y, rotate, scale) }}
          />
      )}
    </>
  )
}
