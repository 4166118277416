import React from "react";
import { trafficLight } from "../../assets";
import './styles';

export default function StationTable({ data }) {
  return (
    <div className="station-table-homepage">
      <div className="item-title-homepage" >
        <div className="d-flex align-items-center">
          <img src={trafficLight} />
          <span className="ml-2">Số pha</span>
        </div>
        <span className="title-num">{ data?.ndevice2Phase + data?.ndevice3Phase + data?.ndevice4Phase + data?.ndevice5Phase }</span>
      </div>
      <div className="item-homepage-content scrollbar-custom-homepage">
        <div className="item-content-border-homepage" >
          <span>Tủ 2 pha</span>
          <span className="text-blue">{data?.ndevice2Phase}</span>
        </div>
        <div className="item-content-border-homepage" >
          <span>Tủ 3 pha</span>
          <span className="text-blue">{data?.ndevice3Phase}</span>
        </div>
        <div className="item-content-border-homepage" >
          <span>Tủ 4 pha</span>
          <span className="text-blue">{data?.ndevice4Phase}</span>
        </div>
        <div className="item-content-border-homepage" >
          <span>Tủ 5 pha</span>
          <span className="text-blue">{data?.ndevice5Phase}</span>
        </div>
      </div>
    </div>
  );
}
