import React, { useEffect, useRef, useState } from 'react';
import { GoAhead, TurnAround, TurnLeft, TurnRight } from '../../../assets';
import Moveable  from "react-moveable";
import { SignalType } from '..';
import useOnClickOutside from '../../../hooks';

function ItemText({ textValue, onTab, id, x = 200, y = 200, rotate = 0, scale = 1, onChange }) {
    // const [_scale, _setScale] = useState({ width: 212, height: 40 });
    // const [_rotation, _setRotation] = useState(0);
    const [_text, _setText] = useState(textValue);
    const [isShow, setIsShow] = useState(false);
    const ref = useRef();

    const [target, setTarget] = React.useState();
    const [frame, setFrame] = React.useState({
        translate: [x, y],
        scale: scale,
        rotate: rotate,
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: 150,
        height: 40,
    });

    useOnClickOutside(ref, () => setIsShow(false));

    useEffect(() => {
        _setText(textValue);
    }, [textValue]);

    React.useEffect(() => {
        let it = `target-text-${id}`;
        setTarget(document.getElementById(it));
    }, []);

    return (<>
        <div id={`target-text-${id}`} className="item-text" ref={ref} style={{ height: frame.height * scale , position: 'absolute', top: '0', transform: `translate(${x}px, ${y}px) rotate(${rotate}deg` }} onClick={() => {
            setIsShow(true);
            onTab(id);
        }}>
            {_text}
        </div>
        {isShow && <Moveable
            target={target}
            origin={false}
            draggable={true}
            keepRatio={true}
            resizable={true}
            resizeEdge={true}
            edge={['n', 'w']}
            onResizeStart={({ target , clientX, clientY}) => {
            }}
            onResize={({
                target, width, height,
                dist, delta, direction,
                clientX, clientY,
            }) => {
                delta[0] && (target.style.width = `${width}px`);
                delta[1] && (target.style.height = `${height}px`);
                setFrame({
                    ...frame,
                    scale: width * 1.0 / frame.width
                })
            }}
            onResizeEnd={({ target, isDrag, clientX, clientY }) => {
            }}
            onDragStart={e => {
                e.set(frame.translate);
            }}
            onDrag={e => {
                frame.translate = e.beforeTranslate;
            }}
            rotatable={true}
            throttleRotate={0}
            onRotateStart={e => {
                e.set(frame.rotate);
            }}
            onRotate={e => {
                frame.rotate = e.beforeRotate;
            }}
            onRender={e => {
                const { translate, rotate, scale } = frame;
                e.target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`
                    + ` rotate(${rotate}deg)`;
                onChange(translate[0], translate[1], rotate, scale);
            }}
        />}
    </>);
}


function ItemArrowDirection({ id, direction, onTab, x = 200, y = 200, rotate = 0, scale = 1, color = 'green', onChange}) {
    const [isShow, setIsShow] = useState(false);
    const ref = useRef();
    const [target, setTarget] = React.useState();
    const [frame, setFrame] = React.useState({
        translate: [x, y],
        scale: scale,
        rotate: rotate,
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 0,
        width: 144,
        height: 90,
    });

    useOnClickOutside(ref, () => setIsShow(false));

    React.useEffect(() => {
        let _frame={...frame};
        switch (SignalType) {
            case SignalType.turnLeft:
                _frame.width = 60;
                _frame.height = 64;
            case SignalType.turnRight:
                _frame.width = 60;
                _frame.height = 64;
            case SignalType.goAhead:
                _frame.width = 34;
                _frame.height = 75;
        }
        setFrame(_frame);
        let it = `target-${id}`;
        setTarget(document.getElementById(it));
    }, [SignalType]);

    return (<>
        <div ref={ref} onClick={() => {
            setIsShow(true);
            onTab(id);
        }} className="position-absolute" style={{ top: '0', width: frame.width * frame.scale, height: frame.height * frame.scale, transform: `translate(${x}px, ${y}px) rotate(${rotate}deg` }} onDoubleClick={() => { onTab() }} id={`target-${id}`}>
            {(() => {
                switch (direction) {
                    case SignalType.turnLeft:
                        frame.width = 60;
                        frame.height = 64;
                        return <TurnLeft color={color} width="100%" height="100%" />;
                    case SignalType.turnRight:
                        frame.width = 60;
                        frame.height = 64;
                        return <TurnRight color={color} width="100%" height="100%" />;
                    case SignalType.goAhead:
                        frame.width = 34;
                        frame.height = 75;
                        return <GoAhead color={color} width="100%" height="100%" />;
                }
            })()}
        </div>

        {isShow && <Moveable
            target={target}
            origin={false}
            draggable={true}
            keepRatio={true}
            resizable={true}
            resizeEdge={true}
            edge={['n', 'w']}
            onResizeStart={({ target , clientX, clientY}) => {
            }}
            onResize={({
                target, width, height,
                dist, delta, direction,
                clientX, clientY,
            }) => {
                delta[0] && (target.style.width = `${width}px`);
                delta[1] && (target.style.height = `${height}px`);
                setFrame({
                    ...frame,
                    scale: width * 1.0 / frame.width
                })
            }}
            onResizeEnd={({ target, isDrag, clientX, clientY }) => {
            }}
            onDragStart={e => {
                e.set(frame.translate);
            }}
            onDrag={e => {
                frame.translate = e.beforeTranslate;
            }}
            rotatable={true}
            throttleRotate={0}
            onRotateStart={e => {
                e.set(frame.rotate);
            }}
            onRotate={e => {
                frame.rotate = e.beforeRotate;
            }}
            onRender={e => {
                const { translate, rotate, scale } = frame;
                // e.target.style.transformOrigin = transformOrigin;
                e.target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`
                    + ` rotate(${rotate}deg)`;

                onChange(translate[0], translate[1], rotate, scale);
            }}
        />}
    </>);
}

export { ItemArrowDirection, ItemText };
